//*****************************************************************************
//	  Proyecto: PAE-PAG
//    Titulo: ie9
//    Desripcion: Modulo para
//    Creador: Carlos Casalengua
//    Fecha: 31/05/2017
//******************************************************************************/
// ie9


///*--------------------------------------------------------
//    # IMPORT FILE CONFIG SCSS
//----------------------------------------------------------*/
@import '_config__project';



.ie9{
	.#{$wf__ns}table {
		&__head {
			display:block;
			padding-top: 14px;
			padding-bottom: 10px;
		}
	}
}


// End ie9
